import { ApolloError } from "@apollo/client/core";

export class ApiError extends Error {
  cause:
    | {
        type: ApiErrorType.Validation;
        errors: ValidationError[];
      }
    | undefined;
  constructor(type: ApiErrorType, options: unknown) {
    let message, cause;
    switch (type) {
      case ApiErrorType.Validation:
        message = "validation error";
        cause = {
          type,
          errors: options as ValidationError[],
        };
        break;
      default:
        message = "unknown error";
        break;
    }
    super(message);
    this.cause = cause;
  }
}
export enum ApiErrorType {
  Validation = "validation",
}
export type ValidationError = {
  instancePath: string;
  message: string;
};
type CommonError = {
  path: string[];
  message: string;
  extensions: {
    status?: number;
    code?: string;
    errors: (CommonError | ValidationError)[] | object;
  };
};
export function findValidationErrors(
  e:
    | ApolloError
    | { errors: []; code?: string }
    | { extensions: [] }
    | object[]
    | object
): ValidationError[] {
  if (e instanceof ApolloError) {
    return findValidationErrors(e.graphQLErrors);
  } else if (Array.isArray(e)) {
    return e.flatMap((error) => {
      if (typeof error == "object") {
        return findValidationErrors(error) ?? [];
      }
      return [];
    });
  } else if (e && typeof e == "object") {
    // validation error
    if ("instancePath" in e && "message" in e) {
      return [e as ValidationError];
    }
    // common error
    else if ("extensions" in e) {
      return findValidationErrors((e as CommonError).extensions);
    } else if ("errors" in e) {
      const commonError = e as Partial<CommonError["extensions"]>;
      if (
        commonError?.code == "BAD_USER_INPUT" &&
        typeof commonError.errors == "object" &&
        !Array.isArray(commonError.errors)
      ) {
        return Object.entries(commonError.errors).map(([key, value]) => {
          return {
            instancePath: key,
            message: value.toString(),
          };
        });
      }
      return findValidationErrors((e as CommonError["extensions"]).errors);
    }
  }
  return [];
}
