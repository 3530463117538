import { EventType } from "@hedgehog-live/sdk";

export const getEventType = (type?: EventType | null): string | undefined => {
  switch (type) {
    case EventType.Real:
      return "リアルのみ";
    case EventType.Video:
      return "配信のみ";
    case EventType.VideoAndReal:
      return "ハイブリッド";
    default:
      return undefined;
  }
};
