export const privacyPolicy = `本ポリシーは、株式会社ソフトディバイス（以下、「当社」といいます。）が、本サービス（第2条で定義します。）を提供するに際して、収集する個人情報（第2条で定義します。）の取扱いを定めます。なお、各種用語の定義は、本サービスに関するイベント開催利用規約及びイベント参加規約にしたがいます。

第１条（適用関係）
１	  本ポリシーは、本サービスの利用に関し適用されます。
２	  当社が、当社ウェブサイト上に掲載するプライバシーポリシーその他の個人情報保護方針または本サービスに関する利用規約などにおける個人情報の取扱いに関する規定と、本ポリシーが矛盾または抵触する場合には、本ポリシーが優先します。

第２条（定義）
本ポリシーでは、次の各用語は、次の意味を有します。

本サービス：Hedgehog.Live（ヘッジホッグライブ）
個人情報保護法：個人情報の保護に関する法律（平成15年法律第57号）
法令：法、政令、規則、基準およびガイドラインなど
個人情報：個人情報保護法2条1項の「個人情報」
個人データ：個人情報保護法2条6項の「個人データ」
保有個人データ：個人情報保護法2条7項の「保有個人データ」
統計情報：複数人の情報から共通要素に係る項目を抽出して同じ分類ごとに集計して得られる情報

第３条（当社が本サービスにおいて取得する個人情報）
当社は、本サービスにおいて次の個人情報を適正かつ公正な手段によって取得することがあります。
１	  イベント主催者
(1)	  氏名・住所・電話番号
(2)	  メールアドレス
２	  イベント参加者
(1)	  氏名・住所・電話番号
(2)	  メールアドレス

第４条（利用目的）
当社は、次の利用目的の範囲で、前条の個人情報を取り扱います。
１	  本サービス又は本イベントに関する登録の受付，本人確認，イベント主催者又はイベント参加者の認証等本サービス又は本イベントの開催，運営のため
２	  本サービス又は本イベントに関するご案内，お問合せ等の対応のため
３	  本サービスの開発・提供の目的で実施する各種アンケート調査、キャンペーンの実施のため
４	  本サービスの提供に必要な範囲でイベント主催者又はイベント参加者若しくは動画配信サービス事業者（米国に本店所在地がある企業を含む。）に提供するため
５	  イベント開催利用規約又はイベント参加規約の変更等について通知するため
６	  イベント主催者が販売したグッズその他の商品の販売・提供・送付のため

第５条（第三者提供）
１	  当社は、法令に基づく場合を除き、第三者に対して個人データを提供しません。法令に基づき開示するときは、個人情報保護法その他適用法令を遵守します。
２	  当社は、イベント主催者又はイベント参加者の個人情報を、本サービスの提供に必要な範囲でイベント参加者又はイベント主催者若しくは動画配信サービス事業者（米国に本店所在地がある企業を含む。）に提供する場合があります。

第６条（業務委託）
当社は、第４条の利用目的の達成に必要な範囲内において、イベント主催者又はイベント参加者の個人情報の全部または一部の取扱いを、本サービスの全部又は一部の運営を委託する第三者に委託することがあります。ただし、当社は、当該委託先が、イベント主催者又はイベント参加者の個人情報を適切かつ安全に管理するように監督します。

第７条（共同利用）
当社は、当社グループ企業その他第三者と共同して事業活動を行うとき、その活動に必要な個人情報（検索することができるように体系的に構成されたものに限ります。）。を共同利用することがあります。このとき、あらかじめ利用目的、データ項目、利用者の範囲および管理責任者を明確にし、ご本人に通知または公表します。また、当社は、共同利用者に、個人情報を適切かつ安全に管理することを確約させます。

第８条（保有個人データの開示・訂正などの権利）
１	  当社が管理する保有個人データについて、ご本人またはその代理人から利用目的の通知の請求または開示、訂正・追加・削除、利用停止・消去もしくは第三者提供の停止の請求（以下、「開示等の請求」と言います。）をされる場合は、第１０条のお問い合わせ窓口に以下の情報をご提供ください。
(1)	  氏名
(2)	  住所または居所
(3)	  電話番号
(4)	  法定代理人による請求の場合、法定代理人の氏名、住所または居所、電話番号
(5)	  開示等の請求される保有個人情報を特定するに足りる情報
２	  前項の請求がご本人あるいはその法定代理人からの請求であることを当社が確認できた後、当社は個人情報保護法の定めに基づき遅滞なく保有個人データの開示、訂正・追加・削除、利用停止・消去もしくは第三者提供の停止に応じます。ただし、以下のいずれかに該当する場合、当社は開示、訂正・追加・削除、利用停止・消去もしくは第三者提供に応じる義務を負いません。
(1)	  開示等の請求の対象となる保有個人データが存在しない場合
(2)	  個人情報保護法その他の法令により、当社が開示、訂正・追加・削除、利用停止・消去もしくは第三者提供に応じる義務を負わない場合
３	  第１項の請求に基づいて当社が保有個人データを開示するにあたり、１件あたり、３０００円の手数料を頂戴しておりますので、ご了承ください。

第９条（本ポリシーの変更）
１	  当社は、個人情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本ポリシーを変更することがあります。
２	  前項にもかかわらず、法令上、ご本人の同意が必要となるような内容の変更を行うときは、別途当社が定める方法により、ご本人の同意を取得します。
３	  前２項の変更後の本ポリシーについては、本サービス上または当社ウェブサイトでの掲示その他分かりやすい方法により告知します。

第１０条（お問い合わせ）
当社による個人情報の取扱いに関するご意見、ご質問、ご要望その他お問い合わせは、下記の窓口までお願いします。

〒603-8053
京都府京都市北区上賀茂岩ケ垣内町41 B-LOCK KITAYAMA
株式会社ソフトディバイス
個人情報取扱責任者  八田 晃
連絡先：info@hedgehog.live

2021年9月20日  制定`;

export const eula = `イベント開催利用規約

イベント開催利用規約（以下、「本規約」と言います。）には、株式会社ソフトディバイス（以下、「当社」と言います。）が提供するhedgehog (ヘッジホッグ)（以下、「本サービス」といいます。）における、当社とイベント主催者の皆様との権利義務関係が定められています。イベント主催者の皆様が本サービスを通じてイベントを開催する際には、本規約の全文をお読み頂いたうえで、本規約に同意いただく必要があります。

第１条（定義）
本規約における各用語の定義は、以下のとおりとします。

イベント主催者：本サービスを通じてイベントを開催する者
本イベント：本サービスを通じて開催されるイベント、ウェブセミナー、授業、講演等
本サービス利用契約：本サービスを通じて開催されるイベント、ウェブセミナー、授業、講演等
主催者アカウント：イベント主催者が本サービスを利用する際の認証に必要なＩＤ及びパスワード
イベント参加者：イベント主催者が開催した本イベントに参加する者
チケット：本イベントに参加することを可能にする電子情報もしくはそれを印刷した書類
動画配信：本イベントの映像及び音声を録音又は録画した動画を購入者に対して視聴させること
ライブ配信：本イベントの映像及び音声をリアルタイムでイベント参加者に視聴させること。イベント主催者が一方的に配信する場合だけではなく、イベント参加者が音声又は映像を配信する場合（例えば、ＷＥＢ会議やウェビナーなど）を含みます。

第２条（本サービスの内容）
本サービスは、イベント主催者が開催する本イベントに関して、イベント主催者とイベント参加者の取引を成立させ、代金決済の場を当社が提供するサービスであり、本イベントに関するチケットの売買を含む取引は、イベント主催者とイベント参加者の間で成立します。

第３条（イベント主催者の申込み及び登録）
１	  本サービスを通じて本イベントを開催することを希望する者（以下、「申込者」と言います。）は、本規約を遵守することに同意の上、当社所定の方法で当社が定める一定の情報（以下、「登録情報」と言います。）を当社に提供することで、本サービスの利用の申込みを行うことができます。
２	  当社は、当社の基準に従って申込者による本サービスの利用の可否を判断して、当社がその利用を可と判断した場合に、その旨を申込者に通知し、本サービスの利用に必要な主催者アカウントを発行します。当社がその通知を発信した時点をもって、本サービス利用契約がイベント主催者と当社の間に成立し、イベント主催者は本規約に従って本サービスを利用することができます。
３	  当社が、前項の規定に基づいて申込者の本サービスの利用を否と判断した場合であっても、当社はその理由について開示する義務を負いません。
４	  申込者が未成年の場合、必ず保護者の同意を得てから本サービスの利用の申込みを行わなければなりません。仮に、未成年による本サービスの利用の申込みがあった場合、当社は、保護者の同意を得て申込みを行ったものとして取り扱うことができるものとします。

第４条（登録情報の変更）
登録情報に変更があった場合、イベント主催者は、当社所定の方法でその旨通知・連絡をするものとします。イベント主催者が登録情報の変更を速やかに通知・連絡しなかったことによってイベント主催者に生じた損害について当社は一切責任を負いません。

第５条（本イベントの開催）
１	  イベント主催者は、本イベントの開催を希望するとき、当社が定める本イベントの開催に必要な情報を提供して、本イベントの開催を当社に申し込まなければなりません。
２	  当社は、前項に基づき、イベント主催者から提供を受けた情報に基づき、当該本イベントの開催の可否を判断し、当社がその開催を可と判断した場合に、その旨をイベント主催者に通知し、チケットその他本イベントの開催に必要な情報をイベント主催者に通知します。
３	  当社が、前項に規定に基づいてイベント主催者の本イベントの開催を否と判断した場合であっても、当社はその理由について開示する義務を負いません。
４	  イベント主催者は、本イベントの開催に関するウェブページにおいて、イベント参加者が直接イベント主催者に連絡がとれるように情報（電話番号、メールアドレス、お問い合わせフォームなど）を記載しなければならないものとします。

第６条（認証情報の管理）
１	  イベント主催者は、主催者アカウント及びチケットその他本イベントの開催に必要な情報（以下、すべてを併せて「認証情報」という。）等を、自己の責任において、適切に管理及び保管するものとし、これを第三者に使用をさせ、または貸与、譲渡、名義変更、売買等をすることは一切できないものとします。
２	  前項の認証情報を用いて本イベントが開催された場合、当該本イベントは、当該認証情報を付与されたイベント主催者により行われたものとみなされ、当該認証情報の管理不十分、使用上の過誤、第三者の使用等によってイベント主催者に生じた損害について当社は一切責任を負いません。

第７条（利用料）
イベント主催者が本サービスを利用するにあたり、当社が別途定める利用料の支払いが必要です。

第８条（禁止事項）
イベント主催者は、本サービスを利用するにあたり、以下の各行為又はその恐れのある行為をしてはならないものとします。
(1)法令に違反する行為又は犯罪行為に関連する行為
(2)当社若しくは特定の企業、法人、団体、地域若しくは個人を誹謗又は中傷する行為、差別的な表現を使用する行為
(3)選挙運動、特定の思想・宗教への勧誘を主たる目的とする行為
(4)ポルノ・写真、性的交渉の勧誘その他猥褻な表現を行う行為
(5)児童や青少年の粗暴性若しくは残虐性を高め、犯罪を誘発助長し、又は健全な育成を阻害する行為
(6)未成年者による飲酒・喫煙を誘因又は助長する行為
(7)詐欺、規制薬物の濫用、売春、マネーロンダリング等の犯罪に結びつく行為
(8)公序良俗に反する行為
(9)当社、本サービスの他のイベント主催者又は本イベントにおける出演者その他第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為
(10)他人になりすまして本イベントを開催する行為
(11)本サービスの他のイベント主催者の認証情報を利用する行為
(12)当社のネットワーク又はシステム等への不正アクセス行為
(13)本サービスに関するネットワークまたはシステム等に過度の負荷をかける行為
(14)チケットの転売、貸与等
(15)本サービスを通じてイベント主催者が配信するコンテンツの譲渡、貸与、再配布、公衆送信を本サービス以外の有料サービスを用いて行う行為
(16)同一内容の本イベントを故意で同時に多数回開催する行為
(17)合理的な理由なく本イベントの開催をキャンセルする行為
(18)本イベント又は本サービスの運営を妨害する行為
(19)反社会的勢力等へ利益を供与する行為
(20)その他本サービスの運営にあたり当社が不適切と判断した行為

第９条（第三者のサービス）
当社は、本サービスにおいて、チケットの代金の決済、配信するコンテンツのストリーミング料金の決済、本サービスで利用する動画配信又はライブ配信のためのサービスの利用料金の決済等に第三者のサービスを利用しております。第三者のサービスを利用している部分に関しては、本規約のほか、当該第三者のサービスにおける利用規約に基づく権利義務関係が発生します。また、当該第三者のサービスに関連してトラブル等が発生しても、当社は責任を負いません。

第１０条（決済代行）
１	  当社は、本サービスを通じて開催した本イベントに関するチケットの代金について、イベント主催者から委託を受けてイベント参加者から受領します。
２	  イベント主催者は、本サービスを利用して販売したチケットの代金を含む金銭その他の経済的価値について、その名目の如何を問わず、イベント参加者から直接受領してはならないものとします。
３	  当社は、イベント参加者から受領したチケットの代金から当社が定める一定の手数料を控除のうえ、その残額をイベント主催者が指定する口座に振り込む方法により支払います。

第１１条（キャンセル）
イベント参加者が購入したチケットの対象となる本イベントについて、イベント主催者の事情により本イベントの開催を中止又は延期した場合、イベント参加者に対する返金等は、イベント主催者の責任において対応しなければならないものとし、本イベントの中止又は延期について当社は一切の責任を負いません。

第１２条（動画配信及びライブ配信）
１	  イベント主催者は、当社が定める対価を支払うことにより、動画配信又はライブ配信をすることができます。
２	  前項に基づいてイベント主催者が行う動画配信又はライブ配信について、その配信した内容についてはイベント主催者が一切の責任を負うものとし、当社は一切の責任を負いません。
３	  イベント主催者が動画配信又はライブ配信をする場合、イベント主催者は、イベント参加者のプライバシー、個人情報の取扱いについて全責任を負うものとします。
４	  第１項に基づいてイベント主催者が行う動画配信又はライブ配信について、第三者の著作権を侵害するなど当社が配信することが不適当と認めた場合、当該動画を削除し、又は配信を即時に停止することができます。
５	  本サービスにおいて動画配信又はライブ配信をする場合、第三者のサービス利用規約（https://www.vonage.com/legal/communications-apis/terms-of-use/）の関連する条項を遵守しなければならず、その条項に違反した場合、本規約に違反したものとみなします。

第１３条（物販）
１	  イベント主催者は、本サービスに通じて、イベント主催者が登録した商品その他グッズ（以下、「商品等」といいます。）をイベント参加者に直接販売することができます。ただし、当社は、イベント主催者が登録した商品等が販売することが不適当であると判断した場合、その登録を削除することができます。
２	  商品等の発送及びその送料その他費用の負担は、イベント主催者が行うものとします。
３	  当社は、本サービスを通じて直接イベント参加者に直接販売した商品等の代金について、イベント主催者から委託を受けてイベント参加者から受領します。
４	  イベント主催者は、本サービスを通じて販売した商品等の代金を含む金銭その他の経済的価値について、その名目の如何を問わず、イベント参加者から直接受領してはならないものとします。
５	  当社は、イベント参加者から受領した商品等の代金から当社が定める一定の手数料を控除のうえ、その残額をイベント主催者が指定する口座に振り込む方法により支払います。
６	  商品等の購入者から返品の要請や商品等に関するクレームがあった場合、イベント主催者が返品、代替品の送付その他対応の可否（以下、「返品等」といいます。）を判断したうえで、返品等をする場合、イベント主催者がその送料その他費用を負担するものとします。また、イベント主催者が商品等の返品等を行った場合であっても、当社は既に受領した手数料の返金はしません。

第１４条（知的財産権の帰属等）
本サービスに関連する著作権（著作権法第２７条及び第２８条の権利を含みます。）、商標権、特許権等の一切の知的財産権は、当社又は当社にその利用許諾をしている者に帰属します。

第１５条（個人情報の取扱い）
１	  本サービスに関連して、当社がイベント主催者の個人情報を取得した場合、個人情報保護法及び関連するガイドライン、当社が定めるプライバシーポリシー（https://hedgehog.live/pp/）等に従って、以下の利用目的の範囲で個人情報を取り扱うものとし、イベント主催者は、その範囲内で当社がイベント主催者の個人情報を取り扱うことについて同意するものとします。
(1)	  本サービス又は本イベントに関する登録の受付、本人確認、イベント主催者の認証等本サービス又は本イベントの開催、運営のため
(2)	  本サービス又は本イベントに関するご案内、お問合せ等の対応のため
(3)	  本サービスの開発・提供の目的で実施する各種アンケート調査、キャンペーンの実施のため
(4)	  本サービスの提供に必要な範囲でイベント参加者又は本サービスで利用する動画配信又はライブ配信のためのサービス事業者（米国に本店所在地がある企業を含む。）に提供するため
(5)	  本規約の変更等について通知するため
(6)	  イベント主催者が販売したグッズその他の商品の販売・提供のため
２	  当社は、前項の利用目的の達成に必要な範囲内において、イベント主催者の個人情報の全部または一部の取扱いを、本サービスの全部又は一部の運営を委託する第三者に委託することがあります。ただし、当社は、当該委託先が、イベント主催者の個人情報を適切かつ安全に管理するように監督します。
３	  当社は、イベント主催者の個人情報（氏名、住所、電話番号、電子メールアドレス等）を、本サービスの提供に必要な範囲でイベント参加者又は本サービスで利用する動画配信又はライブ配信のためのサービス事業者（米国に本店所在地がある企業を含む。）に提供する場合があり、イベント主催者はその提供について同意するものとします。
４	  米国の個人情報保護法制の内容は以下のリンクをご確認ください。
https://www.ppc.go.jp/files/pdf/USA_report.pdf

第１６条（自己責任）
１	  本イベントにおいて使用する楽曲等の著作権の利用許諾等の権利処理は、イベント主催者の責任で行うものとします。
２	  イベント主催者が、本イベントの開催に関連して、第三者に損害を与えた場合又は第三者との間で紛争が生じた場合、イベント主催者は自己の責任と費用において解決するものとします。

第１７条（免責）
１	  本サービスを通じて本イベントの開催を申し込み、当社がその本イベントの開催を承認した後に、本イベントを中止又は延期をする場合、イベント主催者の責任と判断の下で行わなければならず、本イベントの中止又は延期に伴い発生した紛争や損害に関して、当社は一切責任を負いません。
２	  本イベントのイベント主催者と、他のイベント主催者又は出演者若しくはイベント参加者との間で発生した紛争や損害については、当社は一切責任を負わないものとします。
３	  当社は、イベント主催者が本サービスを通じて行う動画配信又はライブ配信について、映像及び音声の品質を保証することはできません。

第１８条（損害賠償）
１	  当社が本サービスに関してイベント主催者に対し負う責任の範囲は、債務不履行責任、不法行為責任、その他法律上の請求原因の如何を問わず、当社の本サービス利用契約の違反が直接の原因でイベント主催者に発生した通常損害に限定され、当社の責に帰すことができない事由から生じた損害、当社の予見の有無を問わず特別の事情から生じた損害等、逸失利益について当社は責任を負わないものとします。
２	  当社に故意又は重大な過失がある場合、前項の定めは適用されません。

第１９条（本サービスの中止又は中断）
１	  当社は、以下のいずれかに該当する場合には、イベント主催者に事前に通知又は連絡することなく、本サービスの中止又は中断をすることができるものとします。
(1)	  サーバ、通信回線その他の本サービスのための設備の故障、障害の発生またはその他の事由により本サービスの提供ができなくなった場合
(2)	  本サービスのシステムの保守、点検、修理、変更を定期的または緊急で行う場合
(3)	  火災、停電などにより本サービスの提供ができなくなった場合
(4)	  地震、噴火、洪水、津波などの天災により本サービスの提供ができなくなった場合
(5)	  戦争、変乱、暴動、騒乱、疫病の蔓延等その他不可抗力により本サービスの提供ができなくなった場合
(6)	  その他、運用上、技術上当社が本サービスの中止又は中断を必要と判断した場合
２	  当社は、前項に定める本サービスの中止又は中断（それに伴い本イベントが開催できなかった場合を含みます。）によりイベント主催者に損害が生じたとしても、その損害について、一切責任を負わないものとします。

第２０条（本サービスの内容の変更）
１	  当社は、いつでも本サービスの内容の全部または一部を変更することができます。
２	  当社が前項の措置をとる場合、当社は、本ウェブサイトに表示することによりイベント主催者に対して通知・連絡するものとします。
３	  当社は、本条第１項に基づき当社が行った措置に基づきイベント主催者に生じた損害について一切責任を負いません。

第２１条（通知・連絡）
１	  当社からイベント主催者への通知・連絡は、電子メールによる送信又は本ウェブサイトへの表示その他当社が適当と認める方法により行われるものとします。
２	  当社が電子メールを用いてイベント主催者に対して通知を行う場合には、当該電子メールを当社がイベント主催者に向けて発信した時点をもって、イベント主催者に通知が到達したものとみなします。

第２２条（権利義務移転の禁止）
イベント主催者は、当社の書面による事前の承諾のない限り、本規約によって生じる権利義務若しくは本サービス利用契約契約の権利義務の全部又は一部を第三者に譲渡し、担保に供し、又はその他の処分をしてはなりません。

第２３条（分離可能性）
本規約のいずれかの条項又はその一部が、法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。

第２４条（準拠法）
本規約の成立、効力、履行及び解釈に関する準拠法は日本法とします。

第２５条（管轄）
本規約に関する一切の紛争については、京都地方裁判所を第一審の専属的合意管轄裁判所とすることを予め合意します。


2021年9月20日制定
2023年2月10日改定
`;

export const scta = `サービス提供事業者
株式会社ソフトディバイス

運営責任者
八田  晃

住所
〒603-8053  京都市北区上賀茂岩ヶ垣内町41 B-LOCK KITAYAMA

お問い合わせ先
info@hedgehog.live

サービス名称
Hedgehog Live（ヘッジホッグライブ）

提供商品
提供サービス内で利用可能なデジタルコンテンツおよびチケット
提供サービス内の提携業者によるグッズ

提供料金
デジタルコンテンツ：各コンテンツ毎に消費税別の価格で記載
グッズ：各販売ページ毎に消費税別の価格で記載

提供料金以外に必要な費用
消費税
通信料
配送が必要な商品の場合、販売ページに記載がなければ送料は全国一律無料

引渡し時期
デジタルコンテンツ：申込み完了後、即時
配送が必要な商品の場合、各販売ページ毎に記載。当該記載をご確認ください。

支払方法
クレジットカード決済、Apple Pay / Google Pay / Microsoft Pay 
各販売ページ毎に記載がある場合は、当該記載をご確認ください。

返品・不良品について
商品発送後の返品・返却等はお受け致しかねます。
商品が不良の場合のみ良品と交換致します。

動作環境
PC / Mac : Safari, Google Chrome, Microsoft Edge
iPhone / Android : Google Chrome, Safari
各最新バージョン`;
