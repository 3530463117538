import { StageData } from "@/data/stage";
import { PagedListData } from "@hedgehog-live/sdk";
import {
  InjectionKey,
  nextTick,
  onMounted,
  readonly,
  Ref,
  ref,
  watch,
} from "vue";
import { RouteLocationRaw, useRoute, useRouter } from "vue-router";

export const ListMode = {
  Normal: "Normal",
  SingleSelect: "SingleSelect",
  MultiSelect: "MultiSelect",
} as const;
export type ListMode = (typeof ListMode)[keyof typeof ListMode];

export class BasicListInfo<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static readonly KEY: InjectionKey<BasicListInfo<any>> =
    Symbol("BasicListInfo");

  private readonly router = useRouter();
  private readonly route = useRoute();

  public readonly newPageLink = ref<RouteLocationRaw>();
  public readonly list = ref<T[]>([]) as Ref<T[]>;
  public readonly publicOnly = ref(false);
  public readonly pageWithRouter = ref(false);
  public readonly listMode = ref<ListMode>(ListMode.Normal);
  public readonly initial = ref(true);
  public readonly loading = ref(false);
  public readonly pageLimit = 10;
  public readonly currentPage = ref(1);
  public readonly maxPage = ref(1);
  public readonly itemCount = ref(0);

  private readonly internalSelectedUuids = ref<string[]>([]);
  private readonly loader =
    ref<(limit: number, page: number) => Promise<PagedListData<T> | null>>();

  public readonly selectedUuids = readonly(this.internalSelectedUuids);

  constructor(stageData: StageData, private listRouteName: string) {
    watch(() => this.route.query, this.restorePageFromQuery, { deep: true });
    watch(stageData.currentStage, async () => {
      this.currentPage.value = 1;
      this.maxPage.value = 1;
      this.itemCount.value = 0;
      if (!stageData.currentStage.value) return;
      await this.load();
    });
    watch(this.publicOnly, this.load);
    onMounted(async () => {
      if (this.pageWithRouter.value) {
        await this.restorePageFromQuery();
      } else {
        await this.load();
      }
    });
  }

  public readonly setLoader = (
    l: (limit: number, page: number) => Promise<PagedListData<T> | null>
  ) => {
    this.loader.value = l;
  };

  public readonly load = async () => {
    this.loading.value = true;
    await new Promise((res) => setTimeout(res, 200));
    if (!this.loader.value) return;
    const res = await this.loader.value(this.pageLimit, this.currentPage.value);
    this.list.value = [];
    if (!res) return;
    await nextTick();
    this.list.value = res.list;
    this.currentPage.value = res.currentPage;
    this.maxPage.value = res.maxPage ? res.maxPage : 1;
    this.itemCount.value = res.count;
    this.loading.value = false;
    this.initial.value = false;
  };

  public readonly next = async () => {
    if (this.currentPage.value >= this.maxPage.value) return;
    this.currentPage.value++;
    if (this.pageWithRouter.value) {
      this.router.push({
        query: {
          page: this.currentPage.value,
        },
      });
    } else {
      await this.load();
    }
  };

  public readonly prev = async () => {
    if (this.currentPage.value === 0) return;
    this.currentPage.value--;
    if (this.pageWithRouter.value) {
      this.router.push({
        query: {
          page: this.currentPage.value > 1 ? this.currentPage.value : undefined,
        },
      });
    } else {
      await this.load();
    }
  };

  public readonly jump = async (p: number) => {
    this.currentPage.value = p;
    if (this.pageWithRouter.value) {
      this.router.push({
        query: {
          page: this.currentPage.value > 1 ? this.currentPage.value : undefined,
        },
      });
    } else {
      await this.load();
    }
  };

  public readonly addToSelection = (...uuids: string[]) => {
    if (this.listMode.value === ListMode.Normal) return;
    for (const uuid of uuids) {
      if (this.internalSelectedUuids.value.indexOf(uuid) !== -1) return;
      if (this.listMode.value === ListMode.SingleSelect) {
        this.internalSelectedUuids.value = [];
      }
      this.internalSelectedUuids.value.push(uuid);
    }
  };

  public readonly removeFromSelection = (...uuids: string[]) => {
    if (this.listMode.value === ListMode.Normal) return;
    for (const uuid of uuids) {
      const index = this.internalSelectedUuids.value.indexOf(uuid);
      if (index === -1) return;
      this.internalSelectedUuids.value.splice(index, 1);
    }
  };

  public readonly checkSelected = (uuid: string) => {
    if (this.listMode.value === ListMode.Normal) return false;
    return this.internalSelectedUuids.value.indexOf(uuid) !== -1;
  };

  private readonly restorePageFromQuery = async () => {
    if (!this.pageWithRouter.value) return;
    if (this.route.name !== this.listRouteName) return;
    if (this.route.query.page) {
      const page = parseInt(this.route.query.page as string);
      this.currentPage.value = !isNaN(page) ? page : 1;
    }
    await this.load();
  };
}
