import { ListedStage, UserType } from "@hedgehog-live/sdk";
import { InjectionKey, readonly, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { hhl } from "./hhl";

export class StageData {
  public static readonly KEY: InjectionKey<StageData> = Symbol("StageData");

  private readonly route = useRoute();
  private readonly router = useRouter();

  private readonly stages = ref<ListedStage[]>();
  private readonly stageUuid = ref<string>();
  private readonly roleForStage = ref<UserType>();

  public readonly userRoleForStage = readonly(this.roleForStage);
  public readonly stageList = readonly(this.stages);
  public readonly currentStage = readonly(this.stageUuid);

  constructor() {
    hhl.on("user-updated", async () => {
      this.roleForStage.value = undefined;
      this.reloadStages();
    });

    watch(
      () => this.route.params,
      () => {
        if (this.route.params.stage === this.stageUuid.value) return;
        this.stageUuid.value = this.route.params.stage as string;
        hhl.stageRouteSetup(this.stageUuid.value);
        this.updateUserRole(this.stageUuid.value);
      },
      { deep: true }
    );

    // ホーム画面で最初のステージを選択
    watch(this.stages, () => {
      if (
        this.route.name == "Home" &&
        this.stages.value &&
        this.stages.value.length > 0 &&
        this.stageUuid.value == null
      ) {
        if (
          hhl.lastStage &&
          this.stages.value.find((s) => s.uuid == hhl.lastStage) != null
        ) {
          this.changeStage(hhl.lastStage);
        } else {
          this.changeStage(this.stages.value[0].uuid);
        }
      }
    });
  }

  public reloadStages = async () => {
    if (hhl.user) {
      const list = await hhl.user.admin.stages();
      if (list.length !== 0) {
        this.stages.value = list;
      } else {
        this.stages.value = [];
      }
    } else {
      this.stages.value = undefined;
    }
  };

  public changeStage = async (stageUuid: string) => {
    let target = this.route.meta.parent
      ? (this.route.meta.parent as string)
      : (this.route.name as string);
    if (this.route.name === "Home" || this.route.name === "UserPage") {
      target = "Dashboard";
    }

    this.router.push({
      name: target,
      params: {
        ...this.route.params,
        stage: stageUuid,
      },
    });

    this.updateUserRole(stageUuid);
  };

  private updateUserRole = async (stageUuid?: string) => {
    if (hhl.user && stageUuid) {
      this.roleForStage.value = await hhl.user.getUserRoleForStage(stageUuid);
    } else {
      this.roleForStage.value = undefined;
    }
  };
}
