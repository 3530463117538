import { hhl } from "@/data/hhl";
import { ApiError, ApiErrorType, findValidationErrors } from "@/utils/error";
import axios from "axios";

export const getAdditionalRoomFee = async (stageUuid: string) => {
  if (!hhl.user) return;
  const res = await axios.post(
    "https://managed.api.hedgehog.live/graphql",
    {
      query: `
    query getStage($uuid: String!) {
      stage(uuid: $uuid){
        responseData{
          additionalRoomFee
        }
      }
    }
    `,
      variables: {
        uuid: stageUuid,
      },
    },
    {
      headers: {
        ...hhl.user.getAuthorizedHeader(),
      },
    }
  );
  return res.data.data.stage.responseData.additionalRoomFee as number;
};

export const associateOwnerWithStage = async (
  ownerMailAddress: string,
  stageUuid: string
) => {
  if (!hhl.user) return;
  await axios.post(
    "https://managed.api.hedgehog.live/graphql",
    {
      query: `
  mutation associateOwnerWithStage($email: String!, $stageUuid: String!) {
    associateOwnerWithStage(email: $email, stageUuid: $stageUuid){
      responseData{
        uuid
      }
    }
  }
  `,
      variables: {
        email: ownerMailAddress,
        stageUuid: stageUuid,
      },
    },
    {
      headers: {
        ...hhl.user.getAuthorizedHeader(),
      },
    }
  );
};

interface UpdateData {
  uuid: string;
  ticketFeeRate: number;
  ticketMaxAmount?: number;
  ticketMinAmount?: number;
  freeMaximumTicketNumber: number;
  productFeeRate: number;
  productMaxAmount?: number;
  productMinAmount?: number;
  additionalRoomFee?: number;
  tippingFeeRate: number;
  tippingMaxAmount?: number;
  tippingMinAmount?: number;
}

export const updateStage = async (updateData: UpdateData) => {
  if (!hhl.user) return;
  const res = await axios.post(
    "https://managed.api.hedgehog.live/graphql",
    {
      query: `
    mutation updateStage($updateData: UpdateStageInput!) {
      updateStage(UpdateStageInput: $updateData){
        responseData{
          uuid
        }
      }
    }
    `,
      variables: {
        updateData,
      },
    },
    {
      headers: {
        ...hhl.user.getAuthorizedHeader(),
      },
    }
  );

  const errors = res.data?.errors as [] | undefined;
  if (errors && errors.length > 0) {
    // バリデーションエラー
    const validationErrors = findValidationErrors(res.data);
    if (errors.length > 0) {
      throw new ApiError(ApiErrorType.Validation, validationErrors);
    }
  }
};
