import { Hedgehog, User, UserReference } from "@hedgehog-live/sdk";
import { EventEmitter } from "events";

const mainTitle = "hedgehog dashboard";
enum Keys {
  refreshToken = "hhl/refresh-token",
  lastStage = "hhl/last-stage",
}

// let initialized = false;
// let currentStage: string | null = null;

class Broadcaster extends EventEmitter {
  private initialized = false;
  private currentUser: UserReference | null = null;
  private currentUserData: User | null = null;
  private currentStage: string | null = null;

  public get user() {
    return this.currentUser;
  }

  public get userData() {
    return this.currentUserData;
  }

  public get stage() {
    return this.currentStage;
  }

  public get lastStage() {
    return localStorage.getItem(Keys.lastStage);
  }

  public async ready(): Promise<void> {
    if (this.initialized) return;
    const rt = localStorage.getItem(Keys.refreshToken);
    if (rt) {
      try {
        const u = await Hedgehog.user.signinWithRefreshToken(rt);
        await this.setUser(u);
      } catch (err) {
        localStorage.removeItem(Keys.refreshToken);
      }
    }
    this.initialized = true;
  }

  public async setUser(u: UserReference): Promise<void> {
    this.currentUser = u;
    this.currentUserData = await u.admin.get();
    localStorage.setItem(Keys.refreshToken, u.refreshToken as string);
    this.emit("user-updated");
  }

  public async reloadUser() {
    if (!this.currentUser) return;
    this.currentUserData = await this.currentUser.admin.get();
    this.emit("user-updated");
  }

  public async signout(): Promise<void> {
    if (this.currentUser) {
      await this.currentUser.clearAuth();
      localStorage.removeItem(Keys.refreshToken);
      this.currentUser = null;
      this.currentUserData = null;
      this.emit("user-updated");
    }
  }

  public async memberCheck(stageUuid: string): Promise<boolean> {
    if (!this.currentUser) {
      return false;
    } else {
      const stages = await this.currentUser.admin.stages();
      const s = stages.find((s) => s.uuid === stageUuid);
      if (s) {
        return true;
      } else {
        return false;
      }
    }
  }

  public async stageRouteSetup(stageUuid: string | null): Promise<void> {
    if (this.currentStage === stageUuid) return;
    this.currentStage = stageUuid;
    if (stageUuid != null) {
      localStorage.setItem(Keys.lastStage, stageUuid);
    }

    if (!stageUuid) {
      document.title = mainTitle;
      return;
    }
    Hedgehog.stage.ref(stageUuid).setUser(this.currentUser);
    const name = await Hedgehog.stage.ref(stageUuid).getName();
    document.title = name + " | " + mainTitle;
  }

  public async userRouteSetup(): Promise<void> {
    this.stageRouteSetup(null);
    if (!this.currentUser) return;
    document.title = this.currentUser.email + " | " + mainTitle;
  }

  public on(type: "user-updated", listener: () => void): this;
  public on(type: string, listener: () => void): this {
    return super.on(type, listener);
  }
}

export const hhl = new Broadcaster();
