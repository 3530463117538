/* eslint-disable @typescript-eslint/no-explicit-any */

import { EmailType, TicketType } from "@hedgehog-live/sdk";

// types

export const ModalDialogType = {
  Processing: "Processing",
  Alert: "Alert",
  Confirm: "Confirm",
  TicketIssue: "TicketIssue",
  MessagePost: "MessagePost",

  EventSelect: "EventSelect",
  TicketSelect: "TicketSelect",
  ProductSelect: "ProductSelect",
  TippingSelect: "TippingSelect",

  SettingInfoEdit: "SettingInfoEdit",
  SettingGuestEdit: "SettingGuestEdit",
  SettingMailEdit: "SettingMailEdit",

  UpdateUserName: "UpdateUserName",
  UpdatePassword: "UpdatePassword",

  CreateStage: "CreateStage",
} as const;
export type ModalDialogType =
  (typeof ModalDialogType)[keyof typeof ModalDialogType];

interface ButtonSetting {
  label: string;
  color?: string;
  serious?: boolean;
}

// data

export interface BaseModalData {
  type: ModalDialogType;
}

export interface ProcessingModalData extends BaseModalData {
  type: "Processing";
  title: string;
  titleCompleted?: string;
  progress?: () => number | boolean;
}

export interface AlertModalData extends BaseModalData {
  type: "Alert";
  title: string;
  message?: string;
  button: ButtonSetting;
}

export interface ConfirmModalData extends BaseModalData {
  type: "Confirm";
  title: string;
  message?: string;
  positiveButton: ButtonSetting;
  negativeButton: ButtonSetting;
}

export interface TicketIssueModalData extends BaseModalData {
  type: "TicketIssue";
}

export interface MessagePostModalData extends BaseModalData {
  type: "MessagePost";
}

export interface EventSelectModalData extends BaseModalData {
  type: "EventSelect";
  singleSelect: boolean;
  selectedUuids: string[];
}

export interface TicketSelectModalData extends BaseModalData {
  type: "TicketSelect";
  selectedUuids: string[];
}

export interface ProductSelectModalData extends BaseModalData {
  type: "ProductSelect";
  selectedUuids: string[];
}

export interface TippingSelectModalData extends BaseModalData {
  type: "TippingSelect";
  selectedUuids: string[];
}

export interface SettingInfoEditModalData extends BaseModalData {
  type: "SettingInfoEdit";
}

export interface SettingGuestEditModalData extends BaseModalData {
  type: "SettingGuestEdit";
}

export interface SettingMailEditModalData extends BaseModalData {
  type: "SettingMailEdit";
  mailType: EmailType;
}

export interface UpdateUserNameModalData extends BaseModalData {
  type: "UpdateUserName";
  currentName: string;
}

export interface UpdatePasswordModalData extends BaseModalData {
  type: "UpdatePassword";
}

export interface CreateStageModalData extends BaseModalData {
  type: "CreateStage";
}

export type ModalDialogData =
  | ProcessingModalData
  | AlertModalData
  | ConfirmModalData
  | TicketIssueModalData
  | MessagePostModalData
  | EventSelectModalData
  | TicketSelectModalData
  | ProductSelectModalData
  | TippingSelectModalData
  | SettingInfoEditModalData
  | SettingGuestEditModalData
  | SettingMailEditModalData
  | UpdateUserNameModalData
  | UpdatePasswordModalData
  | CreateStageModalData;

// result

export interface ConfirmModalResult {
  confirm: boolean;
}

export interface TicketIssuleModalNegativeResult {
  issued: false;
}
export interface TicketIssuleModalPositiveResult {
  issued: true;
  type: TicketType;
  memo: string;
  count: number;
}
export type TicketIssuleModalResult =
  | TicketIssuleModalNegativeResult
  | TicketIssuleModalPositiveResult;

export interface MessagePostNegativeModalResult {
  post: false;
}
export interface MessagePostPositiveModalResult {
  post: true;
  name?: string;
  message: string;
}
export type MessagePostModalResult =
  | MessagePostNegativeModalResult
  | MessagePostPositiveModalResult;

export interface UuidSelectNegativeModalResult {
  selected: false;
}
export interface UuidSelectPositiveModalResult {
  selected: true;
  uuids: string[];
}
export type UuidSelectModalResult =
  | UuidSelectNegativeModalResult
  | UuidSelectPositiveModalResult;

export interface UpdateUserNameModalResult {
  name: string;
}

export interface UpdatePasswordModalResult {
  password: string;
}

export type ModalDialogResult =
  | ConfirmModalResult
  | TicketIssuleModalResult
  | MessagePostModalResult
  | UuidSelectModalResult
  | UpdateUserNameModalResult
  | UpdatePasswordModalResult;

// return type

export type ReturnObjectType<T> = T extends ConfirmModalData
  ? ConfirmModalResult
  : T extends TicketIssueModalData
  ? TicketIssuleModalResult
  : T extends MessagePostModalData
  ? MessagePostModalResult
  : T extends
      | EventSelectModalData
      | TicketSelectModalData
      | ProductSelectModalData
      | TippingSelectModalData
  ? UuidSelectModalResult
  : T extends UpdateUserNameModalData
  ? UpdateUserNameModalResult
  : T extends UpdatePasswordModalData
  ? UpdatePasswordModalResult
  : any;
