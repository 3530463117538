import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/home-view.vue";
import Signin from "@/views/signin-view.vue";
import { basicInitialize } from "./guards";
import { dashbaordRoutes } from "./dashbaord";
import { userRoutes } from "./user";
import { registerRoutes } from "./register";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: basicInitialize,
  },
  ...dashbaordRoutes,
  ...userRoutes,
  ...registerRoutes,
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
    beforeEnter: basicInitialize,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        top: 0,
      };
    }
  },
  routes,
});

export default router;
