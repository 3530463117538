import { RouteRecordRaw } from "vue-router";
import { userPageGuard } from "./guards";
import RouterView from "@/views/user/router-view.vue";
import Top from "@/views/user/top-view.vue";

export const userRoutes: Array<RouteRecordRaw> = [
  {
    path: "/user",
    component: RouterView,
    beforeEnter: userPageGuard,
    children: [
      {
        path: "",
        name: "UserPage",
        component: Top,
      },
    ],
  },
];
