import dayjs from "dayjs";

export const comma = (num: number): string => {
  if (isNaN(num)) return num.toString();
  const n = Number(num);
  return (
    n.toLocaleString().replace(/\..*/, "") + String(n).replace(/^[^.]+/, "")
  );
};
export const formatPrice = (v?: number) => {
  if (!v) return "(未設定)";
  return "￥" + comma(v);
};

export const formatEmailAddres = (a?: string) => {
  return a ? a : "(未設定)";
};

export const selectClickedElement = (e: MouseEvent) => {
  const elm = e.currentTarget as HTMLElement;
  const range = new Range();
  range.selectNode(elm);
  window.getSelection()?.removeAllRanges();
  window.getSelection()?.addRange(range);
};

export const getDisplayDate = (d?: Date | string) => {
  if (!d) return "";
  return dayjs(d).format("YYYY年MM月DD日 HH:mm");
};

export const compareArray = <T>(before: T[], after: T[]) => {
  const remain = before.filter((t) => after.indexOf(t) !== -1);
  const remove = before.filter((t) => after.indexOf(t) === -1);
  const create = after.filter((t) => before.indexOf(t) === -1);
  return { remain, remove, create };
};
