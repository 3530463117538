import { RouteRecordRaw } from "vue-router";
import { basicInitialize } from "./guards";
import Router from "@/views/register/router-view.vue";
import Register from "@/views/register/register-view.vue";
import VerifyRegister from "@/views/register/verify-register-view.vue";

export const registerRoutes: Array<RouteRecordRaw> = [
  {
    path: "/register",
    component: Router,
    beforeEnter: basicInitialize,
    children: [
      {
        path: "",
        name: "Register",
        component: Register,
      },
      {
        path: "verify/:uuid/:hash/",
        name: "RegisterVerify",
        component: VerifyRegister,
      },
    ],
  },
];
