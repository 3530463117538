import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { hhl } from "@/data/hhl";

export const basicInitialize = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> => {
  await hhl.ready();
  hhl.stageRouteSetup(null);
  next();
};

export const dashboardGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> => {
  await hhl.ready();
  const stageUuid = to.params.stage as string;
  hhl.stageRouteSetup(stageUuid);
  const hasPermission = await hhl.memberCheck(stageUuid);
  if (!hasPermission) {
    next({
      name: "Home",
    });
  } else {
    next();
  }
};

export const userPageGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> => {
  await hhl.ready();
  setTimeout(() => {
    hhl.userRouteSetup();
  });
  if (!hhl.user) {
    next({
      name: "Home",
    });
    return;
  }
  next();
};
