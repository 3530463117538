import { EmailTemplate, EmailType, EmailVariable } from "@hedgehog-live/sdk";
import { EmailContent } from "@hedgehog-live/sdk/dist/stage/ref/product/gql";
import { computed, InjectionKey, ref } from "vue";
import { compareArray } from "@/utils";

interface ServiceMethods {
  loadTemplate: () => Promise<EmailTemplate | undefined | null>;
  createTemplate: (data: EmailTemplate) => Promise<void>;
  updateTemplate: (data: EmailTemplate) => Promise<void>;
  getPreview: (data: string) => Promise<string>;
  removeTemplate: () => Promise<void>;
  addMember: (addresses: string[]) => Promise<void>;
  deleteMember: (addresses: string[]) => Promise<void>;
  loadSubscriber: () => Promise<string[]>;
  loadVariables: () => Promise<EmailVariable[]>;
}

const TemplateState = {
  None: "None",
  Create: "Create",
  Update: "Update",
  Delete: "Delete",
} as const;
type TemplateStateType = (typeof TemplateState)[keyof typeof TemplateState];

export class MailEditorData {
  public static readonly KEY: InjectionKey<MailEditorData> =
    Symbol("MailEditorData");

  public readonly emailType = ref<EmailType>(EmailType.Ticket);
  public readonly templateData = ref<
    EmailTemplate | EmailContent | null | undefined
  >();
  public readonly emailVariables = ref<EmailVariable[]>([]);
  public readonly subscriberLoading = ref(false);

  private readonly serverSubscribers = ref<string[]>([]);
  private readonly tempSubscribers = ref<string[]>([]);
  private readonly templateStateBuffer = ref<TemplateStateType>(
    TemplateState.None
  );
  private readonly templateState = ref<TemplateStateType>(TemplateState.None);

  private readonly serviceMethods = ref<ServiceMethods>();
  private readonly templateObtainer = ref<
    () => Promise<EmailTemplate | EmailContent | null | undefined>
  >(async () => {
    return null;
  });

  public readonly subscribers = computed(() => this.tempSubscribers.value);
  public readonly deleteReady = computed(
    () =>
      this.templateState.value === TemplateState.Delete ||
      this.templateState.value === TemplateState.None
  );

  public readonly loadTemplate = async () => {
    this.templateData.value = await this.serviceMethods.value?.loadTemplate();
    if (this.templateData.value)
      this.templateState.value = TemplateState.Update;
  };

  public readonly createTemplate = () => {
    this.templateState.value = TemplateState.Create;
    this.templateData.value = {
      title: "",
      body: "",
    };
  };

  public readonly getPreview = async (data: string) => {
    return await this.serviceMethods.value?.getPreview(data);
  };

  public readonly removeTemplate = async () => {
    this.templateStateBuffer.value = this.templateState.value;
    if (this.templateState.value === TemplateState.Create) {
      this.templateState.value = TemplateState.None;
    } else {
      this.templateState.value = TemplateState.Delete;
    }
  };

  public readonly revertRemovingTemplate = () => {
    this.templateState.value = this.templateStateBuffer.value;
  };

  public readonly addMember = async (address: string) => {
    if (this.tempSubscribers.value.indexOf(address) !== -1) return;
    this.tempSubscribers.value.push(address);
  };

  public readonly deleteMember = async (address: string) => {
    const index = this.tempSubscribers.value.indexOf(address);
    if (index === -1) return;
    this.tempSubscribers.value.splice(index, 1);
  };

  public readonly loadSubscriber = async () => {
    this.subscriberLoading.value = true;
    this.serverSubscribers.value =
      (await this.serviceMethods.value?.loadSubscriber()) as string[];
    this.tempSubscribers.value = this.serverSubscribers.value.concat();
    this.subscriberLoading.value = false;
  };

  public readonly loadVariables = async () => {
    this.emailVariables.value =
      (await this.serviceMethods.value?.loadVariables()) as EmailVariable[];
  };

  public readonly saveMailData = async () => {
    if (this.templateState.value !== TemplateState.None) {
      this.templateData.value = await this.templateObtainer.value();
      if (this.templateData.value) {
        switch (this.templateState.value) {
          case TemplateState.Create:
            await this.serviceMethods.value?.createTemplate(
              this.templateData.value
            );
            this.templateState.value = TemplateState.Update;
            break;
          case TemplateState.Update:
            await this.serviceMethods.value?.updateTemplate(
              this.templateData.value
            );
            break;
          case TemplateState.Delete:
            await this.serviceMethods.value?.removeTemplate();
            this.templateData.value = null;
            this.templateState.value = TemplateState.None;
            break;
        }
      }
    }
    const { create, remove } = compareArray(
      this.serverSubscribers.value,
      this.tempSubscribers.value
    );
    if (create.length !== 0) await this.serviceMethods.value?.addMember(create);
    if (remove.length !== 0)
      await this.serviceMethods.value?.deleteMember(remove);
    await this.loadSubscriber();
  };

  public readonly setupServiceMethods = (methods: ServiceMethods) => {
    this.serviceMethods.value = methods;
  };

  public readonly setupTemplateObtainer = (
    method: () => Promise<EmailTemplate | EmailContent | null | undefined>
  ) => {
    this.templateObtainer.value = method;
  };
}
