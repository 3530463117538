/* eslint-disable @typescript-eslint/no-explicit-any */
import { InjectionKey, readonly, ref } from "vue";
import {
  ModalDialogData,
  ModalDialogResult,
  ReturnObjectType,
} from "./interfaces";
export * from "./interfaces";

export class DialogData {
  public static readonly KEY: InjectionKey<DialogData> = Symbol("DialogData");

  private readonly internalDialogShown = ref(false);
  private readonly internalDialogData = ref<ModalDialogData | null>();

  public readonly dialogShown = readonly(this.internalDialogShown);
  public readonly dialogData = readonly(this.internalDialogData);

  private readonly resolver = ref<
    (() => void) | ((data: any) => void) | null
  >();

  public openDialog = <T extends ModalDialogData>(data: T) => {
    this.internalDialogShown.value = true;
    this.internalDialogData.value = data;
    return new Promise<ReturnObjectType<T>>((res) => {
      this.resolver.value = res;
    });
  };

  public closeDialog = (result?: ModalDialogResult) => {
    this.internalDialogShown.value = false;
    if (this.resolver.value) this.resolver.value(result);
  };
  public wait = (timeSec = 1000) => {
    return new Promise((res) => setTimeout(res, timeSec));
  };
}
