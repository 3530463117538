import { VideoType } from "@hedgehog-live/sdk";

export const getVideTypeLabel = (type: string): string => {
  switch (type) {
    case VideoType.EmbeddedVideo:
      return "埋め込みビデオ";
    default:
      return "(不明)";
  }
};
