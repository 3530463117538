import { RouteRecordRaw } from "vue-router";
import { dashboardGuard } from "./guards";
import RouterView from "@/views/dashboard/router-view.vue";
// import Top from "@/views/dashboard/top-view.vue";

import EventList from "@/views/dashboard/event/list-view.vue";
import EventDetail from "@/views/dashboard/event/detail-view.vue";
import EventEdit from "@/views/dashboard/event/edit-view.vue";

import TicketList from "@/views/dashboard/ticket/list-view.vue";
import TicketDetail from "@/views/dashboard/ticket/detail-view.vue";
import TicketEdit from "@/views/dashboard/ticket/edit-view.vue";

import TippingList from "@/views/dashboard/tipping/list-view.vue";
import TippingDetail from "@/views/dashboard/tipping/detail-view.vue";
import TippingEdit from "@/views/dashboard/tipping/edit-view.vue";

import ProductList from "@/views/dashboard/product/list-view.vue";
import ProductDetail from "@/views/dashboard/product/detail-view.vue";
import ProductEdit from "@/views/dashboard/product/edit-view.vue";

import VideoList from "@/views/dashboard/video/list-view.vue";
import VideoDetail from "@/views/dashboard/video/detail-view.vue";
import VideoEdit from "@/views/dashboard/video/edit-view.vue";

import RoomList from "@/views/dashboard/room/list-view.vue";
import RoomDetail from "@/views/dashboard/room/detail-view.vue";
import RoomEdit from "@/views/dashboard/room/edit-view.vue";

import AutoBroadcastList from "@/views/dashboard/auto-broadcast/list-view.vue";
import AutoBroadcastDetail from "@/views/dashboard/auto-broadcast/detail-view.vue";
import AutoBroadcastEdit from "@/views/dashboard/auto-broadcast/edit-view.vue";

import Setting from "@/views/dashboard/setting/detail-view.vue";

export const dashbaordRoutes: Array<RouteRecordRaw> = [
  {
    path: "/stage/:stage/",
    component: RouterView,
    beforeEnter: dashboardGuard,
    children: [
      {
        path: "",
        name: "Dashboard",
        component: EventList, //Top,
      },

      {
        path: "event",
        name: "EventList",
        component: EventList,
      },
      {
        path: "event/:event",
        name: "EventDetail",
        component: EventDetail,
        meta: {
          parent: "EventList",
          editor: "EventEdit",
        },
      },
      {
        path: "event/:event/edit",
        name: "EventEdit",
        component: EventEdit,
        meta: {
          parent: "EventList",
        },
      },

      {
        path: "video",
        name: "VideoList",
        component: VideoList,
      },
      {
        path: "video/:video",
        name: "VideoDetail",
        component: VideoDetail,
        meta: {
          parent: "VideoList",
          editor: "VideoEdit",
        },
      },
      {
        path: "video/:video/edit",
        name: "VideoEdit",
        component: VideoEdit,
        meta: {
          parent: "VideoList",
        },
      },

      {
        path: "room",
        name: "RoomList",
        component: RoomList,
      },
      {
        path: "room/:room",
        name: "RoomDetail",
        component: RoomDetail,
        meta: {
          parent: "RoomList",
          editor: "RoomEdit",
        },
      },
      {
        path: "room/:room/edit",
        name: "RoomEdit",
        component: RoomEdit,
        meta: {
          parent: "RoomList",
        },
      },

      {
        path: "ticket",
        name: "TicketList",
        component: TicketList,
      },
      {
        path: "ticket/:ticket",
        name: "TicketDetail",
        component: TicketDetail,
        meta: {
          parent: "TicketList",
          editor: "TicketEdit",
        },
      },
      {
        path: "ticket/:ticket/edit",
        name: "TicketEdit",
        component: TicketEdit,
        meta: {
          parent: "TicketList",
        },
      },

      {
        path: "tipping",
        name: "TippingList",
        component: TippingList,
      },
      {
        path: "tipping/:tipping",
        name: "TippingDetail",
        component: TippingDetail,
        meta: {
          parent: "TippingList",
          editor: "TippingEdit",
        },
      },
      {
        path: "tipping/:tipping/edit",
        name: "TippingEdit",
        component: TippingEdit,
        meta: {
          parent: "TippingList",
        },
      },

      {
        path: "product",
        name: "ProductList",
        component: ProductList,
      },
      {
        path: "product/:product",
        name: "ProductDetail",
        component: ProductDetail,
        meta: {
          parent: "ProductList",
          editor: "ProductEdit",
        },
      },
      {
        path: "product/:product/edit",
        name: "ProductEdit",
        component: ProductEdit,
        meta: {
          parent: "ProductList",
        },
      },

      {
        path: "auto-broadcast",
        name: "AutoBroadcastList",
        component: AutoBroadcastList,
      },
      {
        path: "auto-broadcast/:autoBroadcast",
        name: "AutoBroadcastDetail",
        component: AutoBroadcastDetail,
        meta: {
          parent: "AutoBroadcastList",
          editor: "AutoBroadcastEdit",
        },
      },
      {
        path: "auto-broadcast/:autoBroadcast/edit",
        name: "AutoBroadcastEdit",
        component: AutoBroadcastEdit,
      },

      {
        path: "setting",
        name: "DashboardSetting",
        component: Setting,
      },
    ],
  },
];
